import { Clipboard } from '@angular/cdk/clipboard';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, first, map, Subscription } from 'rxjs';
import { Booking } from '../../../interfaces/Booking';
import { User } from '../../../interfaces/User';
import { BookingsService } from '../../../services/api/bookings.service';
import { FrpsService } from '../../../services/api/frps.service';
import { UserService } from '../../../services/api/user.service';
import { SnackService } from '../../../services/helpers/snack.service';
import { TimeService } from '../../../services/helpers/time.service';
import { ActionAlertComponent } from '../../modals/action-alert/action-alert.component';
import { CancelBookingComponent } from '../../modals/cancel-booking/cancel-booking.component';

@Component({
  selector: 'lib-booking',
  templateUrl: './booking.component.html',
})
export class BookingComponent implements OnChanges, OnDestroy {
  @Input() booking?: Booking;
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  bookingForm = this.fb.group({
    frpNote: [ '' ],
  });
  userLoginLink?: string;
  frpNoteChangeRef?: Subscription;
  @Output() bookingEvent = new EventEmitter<string>();
  constructor(
    private dialog: MatDialog,
    public timeService: TimeService,
    public userService: UserService,
    public bookingsService: BookingsService,
    private clipboard: Clipboard,
    private snackSrv: SnackService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private frpService: FrpsService,
  ) {
    this.onFRPNoteChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.booking.currentValue?.id != changes.booking.previousValue?.id) this.fillForm();
    delete this.userLoginLink;
  }

  ngOnDestroy(): void {
    this.frpNoteChangeRef?.unsubscribe();
  }

  fillForm(): void {
    this.bookingForm.setValue({ frpNote: this.booking?.frp?.notes?.notes || '' }, { emitEvent: false });
    setTimeout(() => {
      if(this.autosize) this.autosize.resizeToFitContent(true);
    });
  }
  makeAndCopyLoginLink(employee: User): void {
    this.userService.getLoginLink(employee.firebaseId)
      .pipe(first())
      .subscribe(resp => {
        this.userLoginLink = resp;
        this.copyLoginLink(resp);
        this.cd.detectChanges();
      });
  }

  copyLoginLink(link: string): void {
    const pending = this.clipboard.beginCopy(link);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.snackSrv.systemError('Link was copied to clipboard');
        pending.destroy();
      }
    };
    attempt();
  }


  cancelBooking(): void {
    const dialogRef = this.dialog.open(CancelBookingComponent, {
      width: '486px',
      data: {
        bookingId: this.booking?.id
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(data => {
      if (data && this.booking?.id) this.bookingsService.cancelBooking({
        bookingId: this.booking.id,
        ...data
      }).pipe(first()).subscribe(() => {
        this.bookingEvent.emit('canceled');
      });
    })
  }

  setConfirmingTrip(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Confirm Agreement',
        text: 'Are you sure you wish to confirm trip for this booking?',
        textarea: false,
        confirm: () => {
          if (this.booking?.id) {
            this.bookingsService.setFeePaid(this.booking.id)
              .pipe(first())
              .subscribe(() => {
                this.bookingEvent.emit('confirm_agreement');
              });
          }
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  unsetConfirmingTrip(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Confirm Agreement',
        text: 'Are you sure you wish to back to fee pending for this booking?',
        textarea: false,
        confirm: () => {
          if (this.booking?.id) {
            this.bookingsService.unsetFeePaid(this.booking.id)
              .pipe(first())
              .subscribe(() => {
                this.bookingEvent.emit('confirm_agreement');
              });
          }
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  confirmAgreement(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Confirm Agreement',
        text: 'Are you sure you wish to confirm agreement for this booking?',
        textarea: false,
        confirm: () => {
          if (this.booking?.id) {
            this.bookingsService.confirmAgreement(this.booking.id)
              .pipe(first())
              .subscribe(() => {
                this.bookingEvent.emit('confirm_agreement');
              });
          }
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  confirmPayment(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Confirm Payment',
        text: 'Are you sure you wish to confirm payment for this booking?',
        textarea: false,
        confirm: () => {
          if (this.booking?.id) {
            this.bookingsService.confirmPayment(this.booking.id)
              .pipe(first())
              .subscribe(() => {
                this.bookingEvent.emit('confirm_payment');
              });
          }
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  confirmFlightReady(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Confirm Ready for Flight',
        text: 'Are you sure you wish to confirm flight is ready for this booking?',
        textarea: false,
        confirm: () => {
          if (this.booking?.id) this.bookingsService.confirmFlightReady(this.booking.id)
            .pipe(first())
            .subscribe(() => {
              this.bookingEvent.emit('confirm_flight_ready');
            });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  onFRPNoteChanged(): void {
    this.frpNoteChangeRef = this.bookingForm.get('frpNote')?.valueChanges
      .pipe(
        map((note: string) => {
          return {note, booking: this.booking};
        }),
        debounceTime(1000),
      )
      .subscribe(data => {
        this.frpService.setFrpNote(data.booking?.frpId, data.note)
          .pipe(first())
          .subscribe((savedNote) => {
            if (data.booking) data.booking.frp.notes = savedNote;
          });
      });
  }

  get clientBudget(): number {
    return this.booking?.bookingLegs?.map(leg => leg.price).reduce((sum, a) => sum + a, 0) || 0;
  }
}
