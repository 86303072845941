<form (ngSubmit)="onSubmit()" action="#" [formGroup]="featuredDealForm" class="flight">
  <div class="flight-header">
    <div class="header-logo" [routerLink]="'/featured-flights'">
      <img src="assets/img/gold-logo.svg" alt="" class="header-logo__plane">
      <img src="assets/img/opes-jet.svg" alt="" class="header-logo__text">
    </div>
    <div class="header-title">
      <span>{{featuredFlightId ? 'Update' : 'Create'}} <span class="header-title__request">Empty Leg </span></span>
    </div>
    <div class="header-user">
      <div class="d-f jc-c fd-c">
        <span>{{curUser?.operator?.name || curUser?.email}}</span>
        <div class="user-content">
          <span class="user-content__status" *ngIf="curUser?.operator?.status !== 'gray'" [class.gold]="curUser?.operator?.status === 'gold'" [class.silver]="curUser?.operator?.status === 'silver'" [class.bronze]="curUser?.operator?.status === 'bronze'">{{curUser?.operator?.status}}</span>
        </div>
      </div>
      <img width="44" height="44" [src]="curUser?.operator?.logo || 'assets/img/plane-with-border.svg'" alt="" class="header-user__logo">
    </div>
  </div>
  <div class="flight-body" *ngIf="!successState">
    <div class="body-title">
      <div class="title-column">
        <div class="column-row">
          <mat-form-field appearance="outline" class="title-aircraft">
            <mat-select formControlName="aircraft" placeholder="Choose an aircraft">
              <mat-select-trigger *ngIf="featuredDealForm.value?.aircraft">
                <span [class._oa]="featuredDealForm.value?.aircraft.ownerApproval==='yes'">{{featuredDealForm.value?.aircraft.typeName}} <span class="tailNumber">{{featuredDealForm.value?.aircraft.tailNumber}}</span></span>
              </mat-select-trigger>
              <mat-option *ngFor="let savedAircraft of aircraftsSaved" [value]="savedAircraft">
                <span [class._oa]="savedAircraft.ownerApproval==='yes'">{{savedAircraft.typeName}} {{savedAircraft.tailNumber}}</span>
              </mat-option>
              <mat-option *ngIf="!aircraftsSaved.length" disabled>
                You have no aircrafts, add an aircraft in My Fleet
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column-row">
          <div class="column-row__label">
            <span>Empty Leg condition</span>
          </div>
          <div class="column-row__actions">
            <mat-slide-toggle labelPosition="before" formControlName="emptyLeg">{{featuredDealForm.value?.emptyLeg ? 'Yes' : 'No'}}</mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="title-column">
        <div class="column-row spec">
          <div class="fg-1">Manufactured / Refurbished</div>
          <div>{{featuredDealForm.value?.aircraft?.yom||'-'}}/{{featuredDealForm.value?.aircraft?.yor||'-'}}</div>
        </div>
        <div class="column-row spec">
          <div class="fg-1">Reg.Country</div>
          <div>{{featuredDealForm.value?.aircraft?.homebase?.countryName||'Not added'}}</div>
        </div>
      </div>
      <div class="title-column">
        <div class="column-row">
          <div class="column-row__label">
            <img src="assets/img/passengers/Adult.svg" width="26" height="26" alt="">
            <span class="pl-15">Max passengers</span>
          </div>
          <div class="column-row__actions gold">
            <button type="button" mat-icon-button (click)="changePassengers()" [disabled]="featuredDealForm.value?.maxPassengers === 1">-</button>
            <span>{{featuredDealForm.value?.maxPassengers}}</span>
            <button type="button" mat-icon-button (click)="changePassengers(true)" [disabled]="featuredDealForm.value?.maxPassengers > 17 || featuredDealForm.value?.maxPassengers >= featuredDealForm.value?.aircraft?.seats">+</button>
          </div>
        </div>
        <div class="column-row">
          <div class="column-row__label">
            <img src="assets/img/passengers/white-pets.svg" width="26" height="26" alt="" class="fill-gold">
            <span class="pl-15">Pets allowed</span>
          </div>
          <div class="column-row__actions">
            <mat-slide-toggle labelPosition="before" formControlName="pets">{{featuredDealForm.value?.pets ? 'Yes' : 'No'}}</mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="body-content">
      <mat-card appearance="outlined" class="content-calendar" *ngIf="ready">
        <mat-calendar [minDate]="minDate" [(selected)]="featuredDealForm.value.rangeDate" (selectedChange)="updateExpirationDate($event)" [startAt]="featuredDealForm.value.minDate"></mat-calendar>
      </mat-card>
      <div class="range-date">
        <div class="column-row">
          <div class="label d-f jc-sb ai-c">Flight Date</div>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="startDate" formControlName="minDate" [min]="minDate" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          <div class="dash"></div>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="endDate" formControlName="maxDate" [min]="minDate" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="range-time">
        <div class="column-row">
          <div class="label d-f jc-sb ai-c">Time range</div>
          <mat-form-field appearance="outline">
            <input matInput libTimeMask formControlName="minTime" placeholder="Choose a time" type="text" class="d-f">
          </mat-form-field>
          <div class="dash"></div>
          <mat-form-field appearance="outline">
            <input matInput libTimeMask formControlName="maxTime" placeholder="Choose a time" type="text" class="d-f">
          </mat-form-field>
        </div>
      </div>
      <div class="comment">
        <div class="label d-f jc-sb ai-c">Comment</div>
        <mat-form-field appearance="outline">
          <textarea formControlName="description" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4" cdkAutosizeMaxRows="7" placeholder="Enter comment"></textarea>
          <mat-hint>Please do not disclose your company name and/or contact information until your offer has been accepted by our member. Thank you.</mat-hint>
        </mat-form-field>
      </div>
      <div class="departure">
        <div class="label d-f jc-sb ai-c">Departure airport</div>
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="Select departure airport" aria-label="From Airport" formControlName="from" [matAutocomplete]="fromAirport" autocomplete="off">
          <mat-autocomplete #fromAirport="matAutocomplete" [displayWith]="displayFn">
            <ng-container *ngFor="let city of filteredFromCities">
              <mat-option [value]="city" *ngIf="!(city.cityOnly&&!city.airportOnly&&city.airportsCounter === 1)" [disabled]="city.closed">
                <div class="location">
                  <div class="block-icon">
                    <img src="assets/img/{{!city.cityOnly&&!city.airportOnly ? 'airport' : 'pin'}}.svg" alt="" class="location-ico">
                  </div>
                  <div class="location-city">
                    <div class="location-city__label" *ngIf="city.cityOnly&&city.airportsCounter > 1||city.airportOnly">
                      <span>{{city.city}}</span>
                      <span *ngIf="city.province">, {{city.province}}</span>
                    </div>
                    <div class="location-city__description" [class.airport]="!city.cityOnly&&!city.airportOnly" *ngIf="city.airportOnly||!city.cityOnly&&!city.airportOnly">{{city.name}} ({{city?.icao}})</div>
                  </div>
                  <img *ngIf="city.cityOnly||city.airportOnly" [alt]="city.countryName" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/{{city.countryCode}}.svg" width="17px" height="17px" />
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="destination">
        <div class="label d-f jc-sb ai-c">Destination airport</div>
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="Select destination airport" aria-label="To Airport" formControlName="to" [matAutocomplete]="toAirport" autocomplete="off">
          <mat-autocomplete #toAirport="matAutocomplete" [displayWith]="displayFn">
            <ng-container *ngFor="let city of filteredToCities">
              <mat-option [value]="city" *ngIf="!(city.cityOnly&&!city.airportOnly&&city.airportsCounter === 1)" [disabled]="city.closed">
                <div class="location">
                  <div class="block-icon">
                    <img src="assets/img/{{!city.cityOnly&&!city.airportOnly ? 'airport' : 'pin'}}.svg" alt="" class="location-ico">
                  </div>
                  <div class="location-city">
                    <div class="location-city__label" *ngIf="city.cityOnly&&city.airportsCounter > 1||city.airportOnly">
                      <span>{{city.city}}</span>
                      <span *ngIf="city.province">, {{city.province}}</span>
                    </div>
                    <div class="location-city__description" [class.airport]="!city.cityOnly&&!city.airportOnly" *ngIf="city.airportOnly||!city.cityOnly&&!city.airportOnly">{{city.name}} ({{city?.icao}})</div>
                  </div>
                  <img *ngIf="city.cityOnly||city.airportOnly" [alt]="city.countryName" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/{{city.countryCode}}.svg" width="17px" height="17px" />
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="price">
        <div class="d-f ai-c">
          <div class="d-f ai-c fg-1">
            <button type="button" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" matTooltip="Choose currency" class="mr-15" style="width: 68px">
              <img src="assets/img/currency/{{featuredDealForm.value.priceCurCode}}.svg">
              <mat-icon style="min-width: 24px">expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="w-72">
              <button type="button" mat-menu-item (click)="setCurrency('USD')">USD</button>
              <button type="button" mat-menu-item (click)="setCurrency('EUR')">EUR</button>
              <button type="button" mat-menu-item (click)="setCurrency('GBP')">GBP</button>
            </mat-menu>
            <div>Price</div>
          </div>
          <mat-form-field appearance="outline">
            <input matInput formControlName="price">
          </mat-form-field>
        </div>
        <div class="fs-12">Please include any applicable taxes, fees and surcharges</div>
      </div>
    </div>
  </div>
  <div *ngIf="successState" class="success-body">
    <div class="success-container">
      <div class="success-container__title">Thank you!</div>
      <div class="success-container__text1">
        <span>The empty leg has been {{featuredFlightId ? 'updated' : 'created'}} successfully.</span>
      </div>
    </div>
  </div>
  <div class="flight-footer">
    <div class="footer-label">
      <span *ngIf="successState; else defaultState">The empty leg has been {{featuredFlightId ? 'updated' : 'created'}}. <span class="gold">When client selects your offer, you will get a confirmation email from OPES JET.</span></span>
      <ng-template #defaultState>
        <span class="gold pointer" (click)="openFeaturedFlightInformation()">How it works</span>
      </ng-template>
    </div>
    <div class="footer-actions">
      <button *ngIf="!successState" mat-flat-button class="footer-actions__confirm" type="submit">{{featuredFlightId ? 'Update' : 'Create'}} empty leg</button>
      <button *ngIf="successState" mat-flat-button class="footer-actions__confirm" type="button" [routerLink]="'/featured-flights'">SEE OTHER EMPTY LEGS</button>
    </div>
  </div>
</form>
