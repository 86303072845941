<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle expanded (opened)="autosize?.resizeToFitContent(true);">
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Booking</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <div class="row jc-fe">
          <div class="description__first">Created</div>
          <div class="description__second">{{booking?.created | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</div>
        </div>
        <div *ngIf="booking?.created !== booking?.updated" class="row jc-fe">
          <div class="description__first">Updated</div>
          <div class="description__second">{{booking?.updated | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</div>
        </div>
      </div>
      <div *ngIf="booking?.status !== 'Landed'&&!booking?.deleted" class="menu">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="w-32 h-32">
          <img src="assets/img/more_vert.svg" alt="">
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs" (click)="cancelBooking()" matTooltip="Cancel Booking" matTooltipPosition="left">
            <div class="d-f ai-c">
              <img src="assets/img/delete.svg" alt="">
              <span class="ml-5">Cancel</span>
            </div>
          </button>
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs && (booking?.status === 'WaitingForServiceFee')" (click)="setConfirmingTrip()" matTooltip="Set Confirming Trip" matTooltipPosition="left">
            <div class="d-f ai-c">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none" />
                <title>ic_recommend_24px</title>
                <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm6 9.8a.9.9 0 0 1-.1.5l-2.1 4.9a1.34 1.34 0 0 1-1.3.8H9a2 2 0 0 1-2-2v-5a1.28 1.28 0 0 1 .4-1L12 5l.69.69a1.08 1.08 0 0 1 .3.7v.2L12.41 10H17a1 1 0 0 1 1 1z" />
              </svg>
              <span class="ml-5">Set Confirming Trip</span>
            </div>
          </button>
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs && (booking?.status === 'WaitingForAgreement')" (click)="unsetConfirmingTrip()" matTooltip="Return to Fee Pending" matTooltipPosition="left">
            <div class="d-f ai-c">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/></svg>
              <span class="ml-5">Return to Fee Pending</span>
            </div>
          </button>
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs && (booking?.status === 'WaitingForAgreement' || booking?.status === 'WaitingForSignature' || booking?.status === 'PreparingForFlight')" (click)="confirmAgreement()" matTooltip="Confirm Agreement" matTooltipPosition="left">
            <div class="d-f ai-c">
              <mat-icon>playlist_add_check</mat-icon>
              <span class="ml-5">Set Signing Contract</span>
            </div>
          </button>
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs && (booking?.status === 'CheckingAgreement' || booking?.status === 'WaitingForPayment' || booking?.status === 'WaitingForDeparture')" (click)="confirmPayment()" matTooltip="Confirm Payment" matTooltipPosition="left">
            <div class="d-f ai-c">
              <mat-icon>payment</mat-icon>
              <span class="ml-5">Payment</span>
            </div>
          </button>
          <button type="button" mat-menu-item *ngIf="booking?.bookingLegs && booking?.status === 'PreparingForFlight'" (click)="confirmFlightReady()" matTooltip="Confirm FlightReady" matTooltipPosition="left">
            <div class="d-f ai-c">
              <mat-icon>flight</mat-icon>
              <span class="ml-5">Flight Ready</span>
            </div>
          </button>
        </mat-menu>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form *ngIf="booking" [formGroup]="bookingForm">
    <div class="widget__body request" *ngFor="let leg of booking.bookingLegs; let last = last; index as i">
      <div *ngIf="!i">
        <div class="col mb-10" *ngIf="booking.operator">
          <div class="d-f">
            <div class="fw-b" style="width: 70px;">Operator:</div>
            <div>{{booking.operator.name}}</div>
          </div>
          <div class="d-f jc-sb ai-c">
            <div class="d-f ai-c">
              <span class="fw-b" style="width: 70px;">Login link:</span>
              <mat-form-field appearance="outline">
                <mat-label>Select employee</mat-label>
                <mat-select class="w-200" #employeeSelect>
                  <mat-select-trigger *ngIf="employeeSelect.value">
                    <ng-container *ngIf="employeeSelect.value.firstName && employeeSelect.value.lastName; else noName">
                      {{employeeSelect.value.firstName}} {{employeeSelect.value.lastName}}
                    </ng-container>
                    <ng-template #noName>{{employeeSelect.value.email}}</ng-template>
                  </mat-select-trigger>
                  <mat-option *ngFor="let employee of booking?.operator?.employees || []" [value]="employee">{{employee.firstName}} {{employee.lastName}}<span class="op-06 ml-10">{{employee.email}}</span></mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-f">
              <button *ngIf="employeeSelect.value" class="link-btn" mat-icon-button matTooltip="Make and copy{{userLoginLink ? ' new' : ''}} login link" (click)="makeAndCopyLoginLink(employeeSelect.value)">
                <mat-icon>add_link</mat-icon>
              </button>
              <button class="link-btn ml-10" *ngIf="userLoginLink" mat-icon-button matTooltip="Copy current login link" (click)="copyLoginLink(userLoginLink)">
                <img src="assets/img/copy.svg" alt="">
              </button>
            </div>
          </div>
          <div *ngIf="userLoginLink" class="mb-5">{{userLoginLink}}</div>
        </div>
      </div>
      <div class="d-f">
        <div class="request-datetime">
          <div class="request-datetime__day">{{leg | airportDateTime: 'from' | date: "dd"}}</div>
          <div class="request-datetime__month">{{leg | airportDateTime: 'from' | date: "MMM yyyy"}}</div>
          <div class="request-datetime__time">{{leg | airportDateTime: 'from' : "string_time"}}</div>
        </div>
        <div class="of-hide">
          <div class="d-f ai-c">
            <img class="mx-20" src="assets/img/arrow-up-right.svg" width="20" height="20" alt="">
            <div class="request-icao">
              <span *ngIf="leg.from.name && !leg.fromCityOnly">{{ leg.from.name.replace(' Airport', '') }} ({{ leg.from.icao }})</span>
              <span *ngIf="leg.fromCityOnly">
                <span>{{ leg.from.city }}</span>
                <span *ngIf="leg.from.province">, {{ leg.from.province }}</span>
                <span>, {{ leg.from.countryName }}</span>
              </span>
            </div>
          </div>
          <div class="d-f ai-c">
            <img class="mx-20" src="assets/img/arrow-down-right.svg" width="20" height="20" alt="">
            <div class="request-icao">
              <span *ngIf="leg.to.name && !leg.toCityOnly">{{ leg.to.name.replace(' Airport', '') }} ({{ leg.to.icao }})</span>
              <span *ngIf="leg.toCityOnly">
                <span>{{ leg.to.city }}</span>
                <span *ngIf="leg.to.province">, {{ leg.to.province }}</span>
                <span>, {{ leg.to.countryName }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="leg.aircraftInfo" class="aircraft my-10">
        <div class="aircraft__logo">
          <img [src]="leg.aircraftInfo.pictureExterior || 'assets/img/logo_dark.png'" alt="">
        </div>
        <div class="col jc-sa">
          <div class="gold">{{leg.aircraftInfo.typeName}}</div>
          <div>{{leg.aircraftInfo.tailNumber}} <a *ngIf="booking.status === 'InFlight' || booking.status === 'Mixed' || booking.status === 'Landed'" class="link" href="https://flightaware.com/live/flight/{{leg.aircraftInfo.tailNumber.toUpperCase()}}" target="_blank">Open in FlightAware</a></div>
          <div class="d-f ai-c">
            <img src="assets/img/aircraft/air-type.svg" width="24" height="22" alt="">
            <span class="pl-10 pr-15 d-f ai-c tt-c">{{leg.aircraftInfo.categoryName}} Jet</span>
            <ng-container *ngIf="leg.aircraftInfo.maxSpeed">
              <img src="assets/img/aircraft/speed.svg" width="24" height="24" alt="">
              <span class="pl-10 pr-15 d-f ai-c">{{leg.aircraftInfo.maxSpeed}}</span>
            </ng-container>
            <ng-container *ngIf="leg.aircraftInfo.seats">
              <img src="assets/img/aircraft/max-pas.svg" width="17" height="22" alt="">
              <span class="pl-10 d-f ai-c">{{leg.aircraftInfo.seats}}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="request-field">
        <div class="request-field__name">PASSENGERS</div>
        <div class="request-field__passengers fg-1">
          <div class="flex d-f ai-c">
            <img src="assets/img/passengers/Adult.svg" width="15" height="15" alt="">
            <span class="fw-500">{{leg.adultsNumber}}</span>
          </div>
          <div class="flex d-f ai-c">
            <img src="assets/img/passengers/Children.svg" width="12" height="12" alt="">
            <span class="fw-500">{{leg.childrenNumber}}</span>
          </div>
          <div class="flex d-f ai-c">
            <img src="assets/img/passengers/Infant.svg" width="15" height="15" alt="">
            <span class="fw-500">{{leg.infantsNumber}}</span>
          </div>
          <lib-popup-default class="flex" *ngIf="leg.pets" head="Pets" [description]="leg.petsDescription || 'No pets description'" [hoverOpen]="true">
            <div class="d-f jc-c ai-c" style="filter: invert(81%) sepia(10%) saturate(920%) hue-rotate(1deg) brightness(80%) contrast(85%);">
              <img src="assets/img/passengers/white-pets.svg" width="16" height="16" alt="" style="filter: brightness(0) saturate(100%)">
            </div>
          </lib-popup-default>
          <lib-popup-default class="flex" *ngIf="leg.cargo" head="Comments" [description]="leg.cargoDescription || 'No comments'" [hoverOpen]="true">
            <div class="d-f jc-c ai-c" style="filter: invert(81%) sepia(10%) saturate(920%) hue-rotate(1deg) brightness(80%) contrast(85%);">
              <img src="assets/img/comments-white.svg" width="16" height="16" alt="" style="filter: brightness(0) saturate(100%)">
            </div>
          </lib-popup-default>
        </div>
      </div>
      <!-- <div class="request-category" *ngIf="i === booking.bookingLegs.length - 1">
              <div class="segment">Light</div>
              <div class="segment">Midsize</div>
              <div class="segment">Super Midsize</div>
              <div class="segment">Heavy</div>
              <div class="slider"></div>
          </div> -->
      <div class="request-field" *ngIf="booking.bookingLegs && last">
        <div class="request-field__name">FLIGHT PRICE</div>
        <div class="request-field__budget fg-1">{{leg.priceCurCode}} {{clientBudget | number}}</div>
      </div>
      <div class="request-field" *ngIf="booking.bookingLegs && last && booking.frp && booking.frp.estimatedBudget">
        <div class="request-field__name">ESTIMATED BUDGET</div>
        <div class="request-field__budget grey fg-1">{{leg.priceCurCode}} {{booking.frp.estimatedBudget | number}}</div>
      </div>
    </div>
    <div class="row">
      <div class="widget__field">
        <mat-label class="title">Additional Information</mat-label>
        <mat-form-field style="width: 100%" appearance="outline">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="frpNote"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-expansion-panel>
